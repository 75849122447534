import { ContractingName } from "models/covenant/enums";

const FormatContractNameHelper = {
	getContractName(contractingCompanyName: string) {
		return (
			ContractingName[contractingCompanyName as keyof typeof ContractingName] ||
			contractingCompanyName
		);
	}
};

export default FormatContractNameHelper;
