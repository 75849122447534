import * as Yup from "yup";

import { ErrorHelper } from "helpers";

import { CodeName } from "models/types";

import { BaseModel } from "../BaseModel";
import { Clause } from "./Clause";
import { Monitoring } from "./Monitoring";
import { TaskCompletionProps, TaskProps } from "./types";

export class Task extends BaseModel {
	taskId: string;
	status: CodeName;
	documentUrl?: string;
	documentFile?: File;
	clause: Partial<Clause> | any;
	clauseId: any;
	dueDate: Date;
	teams: Monitoring[];
	frequency?: CodeName;

	constructor(data: TaskProps) {
		super(data);
		this.taskId = data.id;
		this.status = data.status;
		this.dueDate = Task.getValueOrNew(data.dueDate, Date);
		this.clause = Clause.getValueOrNew(data.clause ?? {});
		this.teams = (data.teams || []).map((team) =>
			Monitoring.getValueOrNew(team)
		);
		this.frequency = data.frequency;
	}

	static get initialValues() {
		return {} as TaskCompletionProps;
	}

	get asCompletionValues(): TaskCompletionProps {
		return {
			description: this.clause?.description,
			status: 5,
			documentUrl: "",
			documentFile: undefined,
			covenant: {
				covenantNumber: this.clause?.covenant?.covenantNumber,
				covenantType: this.clause?.covenant?.covenantType
			},
			clauseCategory: this.clause?.clauseCategory?.name,
			dueDate: this.dueDate
		};
	}

	get teamsNames(): string {
		return this.teams.map((team) => team.name).join(", ");
	}
	static get validationSchema() {
		return Yup.object().shape({
			status: Yup.number().required(ErrorHelper.errorMessages.fieldRequired),
			observation: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			),
			documentFile: Yup.string().required(
				ErrorHelper.errorMessages.fieldRequired
			)
		});
	}
}
