export const getStatusType = (
	statusValue: number
): "alert" | "default" | "error" | "success" => {
	return statusValue >= 0.8
		? "success"
		: statusValue > 0.3 && statusValue < 0.8
		? "alert"
		: statusValue <= 0.3
		? "error"
		: "default";
};
