import { IconProps } from "./types";

export default function IcoFAQ({ color = "#1D1D1D", size = "24" }: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM15.3438 10.2812C15.3438 8.71875 13.6875 7.5 12.1562 7.5C10.6875 7.5 9.75 8.125 9.03125 9.21875C8.90625 9.375 8.9375 9.59375 9.09375 9.71875L9.96875 10.375C10.125 10.5 10.375 10.4688 10.5 10.3125C10.9688 9.71875 11.3125 9.375 12.0312 9.375C12.5938 9.375 13.2812 9.71875 13.2812 10.2812C13.2812 10.6875 12.9375 10.875 12.375 11.1875C11.75 11.5625 10.9062 12 10.9062 13.125V13.375C10.9062 13.5938 11.0625 13.75 11.2812 13.75H12.6875C12.9062 13.75 13.0625 13.5938 13.0625 13.375V13.1875C13.0625 12.4062 15.3438 12.375 15.3438 10.2812ZM13.3125 15.5C13.3125 14.7812 12.7188 14.1875 12 14.1875C11.25 14.1875 10.6875 14.7812 10.6875 15.5C10.6875 16.25 11.25 16.8125 12 16.8125C12.7188 16.8125 13.3125 16.25 13.3125 15.5Z"
				fill={color}
			/>
		</svg>
	);
}
