import axios, { AxiosResponse } from "axios";
import useMock from "mock/useMock";

import { baseConfig } from "config";
import { CookieHelper } from "helpers";
import { AuthService } from "./auth";

let api = axios.create({
	baseURL: baseConfig.baseURL
});

api.interceptors.request.use(
	(config: any) => {
		const newConfig = {
			...config,
			headers: {
				...config.headers,
				"Access-Control-Allow-Origin": "*"
			}
		};

		const authService = new AuthService();
		const token = CookieHelper.getCookies(authService.ACCESS_TOKEN);
		if (token) {
			newConfig.headers.Authorization = `Bearer ${token}`;
		}
		return newConfig;
	},
	(error: any) => Promise.reject(error)
);

if (baseConfig.mockRequest) {
	api = axios.create();
	useMock(api);
}

const get = async (url: string, config?: any) => {
	const response: AxiosResponse = await api.get(url, config);
	return response.data;
};

const getUpdatedUsers = async (url: string, config?: any) => {
	await api.get(url, config);
};

const patch = async (url: string, data: any) => {
	const response: AxiosResponse = await api.patch(url, data);
	return response.data;
};

const post = async (url: string, data: any, config?: any) => {
	const response: AxiosResponse = await api.post(url, data, config || {});
	return response.data;
};

const postId = async (url: string, data?: any, config?: any) => {
	const response: AxiosResponse = await api.post(url, data, config || {});
	return response.data;
};

const put = async (url: string, data: any, config?: any) => {
	const response: AxiosResponse = await api.put(url, data, config || {});
	return response.data;
};

const remove = async (url: string, config?: any) => {
	const response: AxiosResponse = await api.delete(url, config || {});
	return response.data;
};

export { get, getUpdatedUsers, patch, post, postId, put, remove };
