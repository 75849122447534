import { Button, Drawer, Input, Loading, Select } from "components";

import { IcoFAQ, IcoPlus, IcoTrash } from "assets/icons";
import colors from "colors";
import ModalCancelConfirm from "components/ModalCancelConfirm";
import { ValueType } from "components/Multiselect/types";
import ToastContent from "components/ToastContent";
import { useFormik } from "formik";
import { ErrorHelper } from "helpers";
import { SelectHelper } from "helpers/SelectHelper";
import { Clause } from "models/covenant/Clause";
import {
	CategoryCreationProps,
	ClauseCreationEditionProps
} from "models/covenant/types";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { CategoryService } from "services/categories";
import { ClauseService } from "services/clauses";
import { TeamService } from "services/teams";
import "./styles.scss";

type ChangeCategoriesDrawerProps = {
	covenantId: string;
	clause: any;
	isOpen: boolean;
	onClose: () => void;
	reloadData: () => void;
};

export default function ChangeCategoriesDrawer({
	covenantId,
	clause,
	isOpen,
	onClose,
	reloadData
}: ChangeCategoriesDrawerProps) {
	const categoryService = useMemo(() => new CategoryService(), []);
	const clauseService = useMemo(() => new ClauseService(), []);
	const teamsService = useMemo(() => new TeamService(), []);

	const [isLoadingCategories, setIsLoadingCategories] = useState(false);
	const [isLoadingTeams, setIsLoadingTeams] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isCreatingNewCategorie, setIsCreatingNewCategorie] = useState(false);
	const [categories, setCategories] = useState<ValueType[]>([]);
	const [newCategory, setNewCategory] = useState({ name: "" });
	const [teams, setTeams] = useState<ValueType[]>([]);
	const [hasErrorsOnSubmit, sethasErrorsOnSubmit] = useState(false);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [hasCategoryChanged, setHasCategoryChanged] = useState(false);
	const [hasTeamChanged, setHasTeamChanged] = useState(false);

	const loadCategories = async () => {
		setIsLoadingCategories(true);
		categoryService
			.listCategories({})
			.then((response) => {
				setCategories(SelectHelper.toValueLabel(response, "id", "name"));
			})
			.catch((errorResponse) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={errorResponse}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => {
				setIsLoadingCategories(false);
			});
	};

	const loadTeams = () => {
		setIsLoadingTeams(true);
		teamsService
			.list({})
			.then((resp) => setTeams(SelectHelper.toValueLabel(resp, "id", "name")))
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => setIsLoadingTeams(false));
	};

	const handleSubmitChanges = async (values: ClauseCreationEditionProps) => {
		setIsLoading(true);
		const handleRequestError = (errorResponse: any) => {
			if (!ErrorHelper.isAuthErrorDisplayed) {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={errorResponse}
						onClose={() => toast.dismiss()}
					/>
				);
			}
		};
		try {
			let data = {
				covenant: covenantId,
				clauseCategory: clause.clauseCategory.id,
				clauseNumber: clause.clauseNumber,
				description: clause.description,
				frequency: clause.teams[0].frequency.code as number,
				monitoringList: values.monitoringList
			};
			const hasTeamDefined = values.monitoringList?.some(
				(value) => value.teamId !== undefined
			);

			if (hasCategoryChanged) {
				data = {
					...data,
					clauseCategory: values.clauseCategory
				};
				await clauseService.editClauseCategorie(
					clause.clauseId,
					values.clauseCategory
				);
			}

			if (hasTeamDefined || hasTeamChanged) {
				await clauseService.editClauseTeams(
					Clause.creationEditionPropsToRequest(data),
					clause.clauseId
				);
			}

			onClose();
			toast.dark(
				<ToastContent
					type="success"
					title="Alterações concluídas!"
					subtitle="A cláusula foi movida para Aba Aprovados."
					onClose={() => toast.dismiss()}
				/>,
				{
					position: "top-center",
					autoClose: 3000,
					closeOnClick: false,
					progressClassName: "confirmation-toast-success-progress",
					className: "confirmation-toast",
					bodyClassName: "confirmation-toast-body"
				}
			);
			reloadData();
		} catch (errorResponse) {
			handleRequestError(errorResponse);
		} finally {
			setIsLoading(false);
		}
	};

	const formik = useFormik({
		initialValues: {
			clauseCategory: undefined,
			monitoringList: [{ teamId: undefined, monitoringLevel: 1 }]
		},
		onSubmit: (values) => {
			handleSubmitChanges(values);
		}
	});

	const closeModal = () => {
		setIsCancelModalOpen(false);
	};

	const confirmModalAction = () => {
		setIsCancelModalOpen(false);
		onClose();
		return false;
	};

	const submitNewCategorie = async (value: CategoryCreationProps) => {
		setIsLoading(true);
		await categoryService
			.createCategory(value)
			.then(() => {
				toast.dark(
					<ToastContent
						type="success"
						title="Alterações concluídas!"
						subtitle="A categoria foi criada com sucesso."
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				loadCategories();
				const createdCategory = categories.find(
					(category) => category.label === value.name
				);

				if (createdCategory) {
					formik.setFieldValue("clauseCategory", createdCategory.value);
				}
			})
			.catch((errorResponse) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={errorResponse}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => {
				setIsLoading(false);
				setIsCreatingNewCategorie(false);
			});
	};

	const addTeam = () => {
		const updatedTeams = formik.values.monitoringList || [];
		updatedTeams.push({
			teamId: undefined,
			monitoringLevel: 1
		});
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	const removeTeam = (index: number) => {
		const updatedTeams = formik.values.monitoringList || [];
		updatedTeams.splice(index, 1);
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	const onChangeTeamNotification = (
		index: number,
		teamId: string | number | undefined,
		notificationLevel: string | number | undefined
	) => {
		const updatedTeams = JSON.parse(
			JSON.stringify(formik.values.monitoringList || [])
		);
		updatedTeams[index] = {
			teamId,
			monitoringLevel: notificationLevel
		};
		formik.setFieldValue("monitoringList", updatedTeams);
	};

	const getTeamValue = (index: number) => {
		if (
			!formik.values.monitoringList ||
			formik.values.monitoringList?.length <= index
		)
			return undefined;

		return teams.find(
			(team) => team.value === formik.values.monitoringList![index]?.teamId
		);
	};

	const onChangeTeams = (selected: any, index: number) => {
		const currentNotification =
			formik.values.monitoringList?.[index]?.monitoringLevel;
		onChangeTeamNotification(index, selected.value, currentNotification);
		setHasTeamChanged(true);
	};

	function getTeamFieldError(
		index: number,
		fieldName: "teamId" | "monitoringLevel"
	): string | number | undefined {
		if (
			!formik.errors.monitoringList ||
			!Array.isArray(formik.errors.monitoringList)
		) {
			return undefined;
		}

		const teamError = formik.errors.monitoringList[index] as
			| { teamId?: string; monitoringLevel?: number }
			| undefined;
		return teamError && teamError[fieldName] ? teamError[fieldName] : undefined;
	}

	function hasTeamFieldError(
		index: number,
		fieldName: "teamId" | "monitoringLevel"
	): boolean {
		if (
			!formik.errors.monitoringList ||
			!Array.isArray(formik.errors.monitoringList)
		) {
			return false;
		}

		const teamError = formik.errors.monitoringList[index] as
			| { teamId?: string; monitoringLevel?: number }
			| undefined;
		return !!teamError && !!teamError[fieldName];
	}

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			sethasErrorsOnSubmit(true);
		} else {
			formik.handleSubmit();
		}
	};

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues);

	useEffect(() => {
		if (isOpen) {
			loadCategories();
			loadTeams();
		}
	}, [isOpen]);

	useEffect(() => {
		if (clause) {
			const categoryId = newCategory.name
				? categories.find((category) => category.label === newCategory.name)
						?.value
				: clause.clauseCategory.id;

			formik.setValues({
				clauseCategory: categoryId,
				monitoringList: clause.teams.map((team: any) => ({
					teamId: team.id,
					monitoringLevel: team.monitoringLevel.code
				}))
			});
		}

		setNewCategory({ name: "" });
	}, [clause, categories]);

	return (
		<>
			<Drawer isOpen={isOpen} onClose={onClose} title="Alterar categoria">
				<form className="clause-details-form">
					<div className="inputs-section">
						<div className="create-categories-container">
							<div className="categories-sub-header">
								<div className="categories-info">
									<p className="info-title">Categoria sugerida</p>
									<Tooltip
										anchorSelect=".create-category-button"
										place="bottom"
										className="z-50"
									>
										<p>
											Nosso sistema identifica automaticamente as <br />
											categorias mais adequadas para cada cláusula <br />
											do contrato. Se alguma categoria não se aplicar, <br />
											você pode selecionar ou criar uma nova.
										</p>
									</Tooltip>
									<Button
										cssClass="create-category-button"
										kind="icon"
										styled="ghost"
									>
										<IcoFAQ size="16" />
									</Button>
								</div>
								<div className="create-categorie-link">
									<Button
										disabled={isCreatingNewCategorie}
										styled="unstyled"
										cssClass="button-link"
										onClick={() => setIsCreatingNewCategorie(true)}
									>
										<IcoPlus
											color={
												isCreatingNewCategorie
													? "#C6C7C8"
													: colors.neutral["low-pure-500"]
											}
										/>
										Criar categoria
									</Button>
								</div>
							</div>
							<div className="clause-info-wrapper" />
							{isLoadingCategories ? (
								<Loading />
							) : (
								<Select
									isDisabled={isCreatingNewCategorie}
									label="Selecione uma categoria"
									options={categories.sort((a, b) =>
										a.label.localeCompare(b.label)
									)}
									placeholder="Selecione a categoria"
									onChange={(selected: any) => {
										formik.setFieldValue("clauseCategory", selected.value);
										setHasCategoryChanged(true);
									}}
									value={categories.find(
										(clauseCategory) =>
											clauseCategory.value === formik.values.clauseCategory
									)}
									error={
										hasErrorsOnSubmit ? formik.errors.clauseCategory : undefined
									}
									className={
										hasErrorsOnSubmit && formik.errors.clauseCategory
											? "select-error"
											: ""
									}
								/>
							)}
							<div className="clause-info-wrapper" />
							{isCreatingNewCategorie && (
								<div className="create-categorie-wrapper">
									<p>NOVA CATEGORIA</p>
									<Input
										type="text"
										label="Informe um nome"
										name="name"
										onChange={(event) =>
											setNewCategory({ name: event.target.value })
										}
										value={newCategory.name}
									/>
									<div className="create-categorie-buttons">
										<Button
											isLoading={isLoading}
											styled="primary"
											onClick={() => submitNewCategorie(newCategory)}
										>
											Salvar categoria
										</Button>
										<Button
											styled="secondary"
											onClick={() => {
												setIsCreatingNewCategorie(false);
												setNewCategory({ name: "" });
											}}
										>
											Cancelar
										</Button>
									</div>
								</div>
							)}
						</div>
						<div className="drawer-divider" />
						<div className="create-team-wrapper">
							<div className="teams-sub-header">
								<p className="info-title">Times Associados</p>
								<div className="create-team-link">
									<Button
										styled="unstyled"
										cssClass="button-link"
										onClick={addTeam}
									>
										<IcoPlus />
										Adicionar time
									</Button>
								</div>
							</div>
							<div className="clause-info-wrapper" />
							{isLoadingTeams ? (
								<Loading />
							) : (
								formik?.values?.monitoringList?.map((team, index) => (
									<div className="create-team-selector">
										<div
											className="create-team-index"
											key={`team-selector-${index}`}
										>
											<p>TIME {index + 1}</p>
											<Button
												disabled={index === 0}
												kind="icon"
												styled="ghost"
												cssClass="trash"
												onClick={() => removeTeam(index)}
											>
												<IcoTrash />
											</Button>
										</div>
										<div className="drawer-divider" />
										<Select
											className={
												hasErrorsOnSubmit && hasTeamFieldError(index, "teamId")
													? "select-error"
													: ""
											}
											label="Nome do time"
											name={`teams[${index}]`}
											options={teams.sort((a, b) =>
												a.label.localeCompare(b.label)
											)}
											placeholder="Selecione um time"
											onChange={(selected: any) =>
												onChangeTeams(selected, index)
											}
											value={getTeamValue(index)}
											error={
												hasErrorsOnSubmit
													? getTeamFieldError(index, "teamId")
													: undefined
											}
										/>
										<div className="team-notification-selector">
											<p>Tipo de notificação</p>
											<div className="team-notification-type">
												<Input
													className="radio-button"
													checked={team.monitoringLevel === 1}
													type="radio"
													label="E-mail"
													name={`monitoringList[${index}].monitoringLevel`}
													value={1}
													onChange={() =>
														formik.setFieldValue(
															`monitoringList[${index}].monitoringLevel`,
															1
														)
													}
												/>
												<Input
													className="radio-button"
													checked={team.monitoringLevel === 2}
													type="radio"
													label="Teams Microsoft"
													name={`monitoringList[${index}].monitoringLevel`}
													value={2}
													onChange={() =>
														formik.setFieldValue(
															`monitoringList[${index}].monitoringLevel`,
															2
														)
													}
												/>
											</div>
										</div>
									</div>
								))
							)}
						</div>
					</div>
					<div className="actions-section">
						<Button
							styled="secondary"
							cssClass="cancel-button"
							onClick={() =>
								hasChanges() ? setIsCancelModalOpen(true) : onClose()
							}
						>
							Cancelar
						</Button>
						<Button
							cssClass="save-button"
							disabled={!hasCategoryChanged && !hasTeamChanged}
							isLoading={isLoading}
							onClick={(e: any) => validateThenHandleSubmit(e)}
						>
							Salvar
						</Button>
					</div>
				</form>
			</Drawer>
			<ModalCancelConfirm
				modalTitle="Deseja sair?"
				modalInfo='Ao clicar em "Sair", suas ações não serão salvas.'
				isOpen={isCancelModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Voltar"
				confirmLabel="Sair"
				toastSuccessTitle="Alteração concluída!"
				toastSuccessMessage="Nova cláusula adicionada"
				kind="warning"
			/>
		</>
	);
}
