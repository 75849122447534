import { Button } from "components";
import { useState } from "react";
import "./styles.scss";

type StatusFilterHeadersProps = {
	onFilterChange: (status: string) => void;
	openTasksCount: number;
	overdueTasksCount: number;
	completedTasksCount: number;
	notAcceptedTasksCount: number;
	siacorpTasksCount: number;
	onPageChange: () => void;
};

export default function StatusFilterHeaders({
	onFilterChange,
	openTasksCount,
	overdueTasksCount,
	completedTasksCount,
	notAcceptedTasksCount,
	siacorpTasksCount,
	onPageChange
}: StatusFilterHeadersProps) {
	const [activeButton, setActiveButton] = useState<string>("open");

	const handleClick = (value: string) => {
		setActiveButton(value);
		onFilterChange(value);
		onPageChange();
	};

	return (
		<div className="status-filter-headers">
			<Button
				onClick={() => handleClick("open")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "open" ? "active" : "inactive"
				}`}
			>
				Em aberto
				{openTasksCount >= 0 && openTasksCount <= 99 ? (
					<span>{openTasksCount}</span>
				) : (
					<span>+99</span>
				)}
			</Button>
			<Button
				onClick={() => handleClick("overdue")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "overdue" ? "active" : "inactive"
				}`}
			>
				Atrasadas
				{overdueTasksCount >= 0 && overdueTasksCount <= 99 ? (
					<span>{overdueTasksCount}</span>
				) : (
					<span>+99</span>
				)}
			</Button>
			<Button
				onClick={() => handleClick("completed")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "completed" ? "active" : "inactive"
				}`}
			>
				Atendidas
				{completedTasksCount >= 0 && completedTasksCount <= 99 ? (
					<span>{completedTasksCount}</span>
				) : (
					<span>+99</span>
				)}
			</Button>
			<Button
				onClick={() => handleClick("notAccepted")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "notAccepted" ? "active" : "inactive"
				}`}
			>
				Não atendidas
				{notAcceptedTasksCount >= 0 && notAcceptedTasksCount <= 99 ? (
					<span>{notAcceptedTasksCount}</span>
				) : (
					<span>+99</span>
				)}
			</Button>

			<Button
				onClick={() => handleClick("siacorp")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "siacorp" ? "active" : "inactive"
				}`}
			>
				Siacorp
				{siacorpTasksCount >= 0 && siacorpTasksCount <= 99 ? (
					<span>{siacorpTasksCount}</span>
				) : (
					<span>+99</span>
				)}
			</Button>
		</div>
	);
}
