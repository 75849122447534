import { Button } from "components";
import React, { useState } from "react";
import "./styles.scss";

type StatusFilterHeadersProps = {
	onFilterChange: (status: string) => void;
	openClausesCount: number;
	completedClausesCount: number;
};

function StatusFilterHeaders({
	onFilterChange,
	openClausesCount,
	completedClausesCount
}: StatusFilterHeadersProps) {
	const [activeButton, setActiveButton] = useState<string>("open");

	const handleClick = (value: string) => {
		setActiveButton(value);
		onFilterChange(value);
	};

	return (
		<div className="status-filter-headers">
			<Button
				onClick={() => handleClick("open")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "open" ? "active" : "inactive"
				}`}
			>
				Em aberto
				{openClausesCount > 0 && openClausesCount <= 99 ? (
					<span>{openClausesCount}</span>
				) : !openClausesCount ? (
					<span>0</span>
				) : (
					<span>+99</span>
				)}
			</Button>
			<Button
				onClick={() => handleClick("completed")}
				size="small"
				styled="ghost"
				cssClass={`status-filter-button" ${
					activeButton === "completed" ? "active" : "inactive"
				}`}
			>
				Aprovados
				{completedClausesCount >= 0 && completedClausesCount <= 99 ? (
					<span>{completedClausesCount}</span>
				) : !completedClausesCount ? (
					<span>0</span>
				) : (
					<span>+99</span>
				)}
			</Button>
		</div>
	);
}

export default React.memo(StatusFilterHeaders);
