import clsx from "clsx";

import { Loading } from "components";

import { ButtonProps } from "./types";

import "./styles.scss";

function getLoadingIcon(styled: string) {
	switch (styled) {
		case "primary":
			return "neutral";
		case "secondary":
		case "ghost":
		case "transparent":
		default:
			return "primary";
	}
}

export default function Button({
	kind = "default",
	styled = "primary",
	type = "button",
	disabled = false,
	size = "medium",
	onClick,
	leading,
	trailing,
	children,
	cssClass,
	isLoading,
	...args
}: ButtonProps) {
	return (
		<button
			type={type}
			className={clsx(`button-component ${cssClass || ""}`, {
				"button-default": kind === "default",
				"button-link": kind === "link",
				"button-icon": kind === "icon",
				"button-chip": kind === "chip",
				"button-primary": styled === "primary",
				"button-secondary": styled === "secondary",
				"button-ghost": styled === "ghost",
				"button-transparent": styled === "transparent",
				"button-unstyled": styled === "unstyled",
				"button-is-loading": isLoading === true,
				"button-small": size === "small",
				"button-medium": size === "medium",
				"button-large": size === "large",
				"button-disabled": disabled
			})}
			disabled={disabled || isLoading}
			onClick={onClick}
			{...args}
		>
			<div className="button-children">
				{leading && <span className="first-icon">{leading}</span>}
				{children}
				{trailing && <span className="last-icon">{trailing}</span>}
			</div>
			<Loading type={getLoadingIcon(styled)} size={24} />
		</button>
	);
}
