import Cookies from "js-cookie";

const CookieHelper = {
	setCookies(name: string, value: any, expire = 0) {
		const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
		// const ONE_MINUTE_IN_MILLIS = 60 * 1000;

		const expireInMillis = expire * ONE_DAY_IN_MILLIS;
		const expirationDate = new Date();

		expirationDate.setTime(expirationDate.getTime() + expireInMillis);
		return Cookies.set(name, value, { expires: expirationDate });
	},

	getAllCookies() {
		return Cookies.get();
	},

	getCookies(name: string) {
		return Cookies.get(name);
	},

	removeCookie(name: string) {
		Cookies.remove(name);
	}
};

export default CookieHelper;
