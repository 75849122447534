import { IcoSignalCheck } from "assets/icons";
import { Button } from "components";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

export default function CovenantCreationSuccess() {
	const { id } = useParams();
	const navigate = useNavigate();

	return (
		<div className="covenant-creation-success stack--4">
			<IcoSignalCheck />
			<div className="stack--3">
				<div className="title">Contrato criado para validação!</div>
				<div className="description">
					Agora é hora de revisar as categorias das cláusulas do contrato.
				</div>
			</div>
			<div className="stack--3">
				<Button
					kind="default"
					styled="primary"
					onClick={() => navigate(`/covenant-analysis/${id}`)}
				>
					Ir para validação de categorias
				</Button>
				<Button
					kind="default"
					styled="secondary"
					onClick={() => navigate("/covenants")}
				>
					Voltar
				</Button>
			</div>
		</div>
	);
}
