import { IconProps } from "./types";

export default function IcoReverter({
	color = "#1D1D1D",
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 19 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 16C4.71945e-08 15.8674 0.052689 15.7402 0.146475 15.6464L5.14729 10.6464C5.34257 10.4512 5.65915 10.4512 5.8544 10.6465C6.04964 10.8418 6.04962 11.1583 5.85434 11.3536L1.70725 15.5H18.5C18.7761 15.5 19 15.7239 19 16C19 16.2761 18.7761 16.5 18.5 16.5H1.70724L5.85434 20.6464C6.04962 20.8417 6.04964 21.1582 5.8544 21.3535C5.65915 21.5488 5.34257 21.5488 5.14729 21.3536L0.146475 16.3536C0.052689 16.2598 -1.73904e-08 16.1326 0 16Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 6C19 6.13262 18.9473 6.25981 18.8535 6.35358L13.8527 11.3536C13.6574 11.5488 13.3408 11.5488 13.1456 11.3535C12.9504 11.1582 12.9504 10.8417 13.1457 10.6464L17.2928 6.5L0.5 6.5C0.223858 6.5 4.25043e-07 6.27614 4.37114e-07 6C4.49185e-07 5.72386 0.223858 5.5 0.5 5.5L17.2928 5.5L13.1457 1.35358C12.9504 1.15834 12.9504 0.841753 13.1456 0.646475C13.3408 0.451197 13.6574 0.451172 13.8527 0.646418L18.8535 5.64642C18.9473 5.74019 19 5.86738 19 6Z"
				fill={color}
			/>
		</svg>
	);
}
