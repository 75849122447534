import { Button, Drawer } from "components";

import { IcoArrowRight } from "assets/icons";
import colors from "colors";
import { Clause } from "models/covenant/Clause";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

type ClausesDetailsDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	clause: Clause;
};

export default function ClauseDetailsDrawer({
	isOpen,
	onClose,
	clause
}: ClausesDetailsDrawerProps) {
	const navigate = useNavigate();
	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Detalhes">
			<div className="clause-details-drawer">
				<p className="info-title">Informações da cláusula</p>
				<div className="clause-info-wrapper">
					<div className="clause-info-row">
						<span className="clause-info-label">Número da cláusula</span>
						<span className="clause-info-value">{clause?.clauseNumber}</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Categoria da cláusula</span>
						<span className="clause-info-value">
							{clause?.clauseCategory?.name}
						</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Início</span>
						<span className="clause-info-value">
							{moment(clause?.startDate).format("DD/MM/YYYY")}
						</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Vencimento da cláusula</span>
						<span className="clause-info-value">
							{moment(clause?.completionDate).format("DD/MM/YYYY")}
						</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Vencimento do contrato</span>
						<span className="clause-info-value">
							{moment(clause?.covenant?.expirationDate).format("DD/MM/YYYY")}
						</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Status</span>
						<span className="clause-info-value">{clause?.status?.name}</span>
					</div>
					<div className="clause-info-row">
						<span className="clause-info-label">Periodicidade</span>
						<span className="clause-info-value">{clause?.frequency?.name}</span>
					</div>
				</div>
				<p className="info-title">Contrato associado</p>
				<div className="clause-info-wrapper">
					<div className="clauses-row">
						<span className="clause-info-label">
							{clause?.covenant?.covenantNumber}
						</span>
						<span className="">
							<Button
								cssClass="right-arrow"
								kind="icon"
								styled="ghost"
								type="button"
								onClick={() =>
									navigate(`/covenants/details/${clause?.covenant?.covenantId}`)
								}
							>
								<IcoArrowRight color={colors.neutral["low-pure-500"]} />
							</Button>
						</span>
					</div>
				</div>
				<p className="info-title">Descrição da cláusula</p>
				<div className="clause-info-wrapper">
					<span className="clause-info-label">{clause?.description}</span>
				</div>
				<p className="info-title">Times associados</p>
				{clause?.teams?.length > 0 ? (
					clause?.teams.map((team, index) => (
						<div key={`team-info-${team?.id}`} className="clause-info-wrapper">
							<div className="clauses-row">
								<div key={index} className="clause-info-label">
									{team?.name}
								</div>
								<span>
									<Button
										cssClass="right-arrow"
										kind="icon"
										styled="ghost"
										type="button"
										onClick={() => navigate(`/teams/edit/${team?.id}`)}
									>
										<IcoArrowRight color={colors.neutral["low-pure-500"]} />
									</Button>
								</span>
							</div>
						</div>
					))
				) : (
					<div className="clause-info-wrapper">
						<div className="clauses-info-row">
							<span className="clause-info-label">Sem times associados</span>
						</div>
					</div>
				)}
			</div>
		</Drawer>
	);
}
