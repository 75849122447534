import { useState } from "react";
import { toast } from "react-toastify";

import { Button } from "components";
import Modal from "components/Modal";

import ToastContent from "components/ToastContent";
import "./styles.scss";

type ModalCancelConfirmProps = {
	modalTitle: string;
	changeButtonsPriority?: boolean;
	modalInfo: string;
	isOpen: boolean;
	onClose: () => void;
	onCloseButtonClick?: () => Promise<void> | void | boolean;
	onConfirm: () => Promise<void> | void | boolean;
	closeLabel?: string;
	confirmLabel?: string;
	toastSuccessTitle: string;
	toastSuccessMessage: string;
	reloadTable?: () => void;
	kind?: "default" | "warning" | "error" | "success";
};

export default function ModalCancelConfirm({
	modalTitle,
	changeButtonsPriority = false,
	modalInfo,
	isOpen,
	onClose,
	onCloseButtonClick = onClose,
	onConfirm,
	closeLabel = "Cancelar",
	confirmLabel = "Confirmar",
	toastSuccessTitle,
	toastSuccessMessage,
	reloadTable,
	kind
}: ModalCancelConfirmProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const confirm = async () => {
		try {
			const showSuccessToast = await onConfirm();
			setIsLoading(false);

			if (showSuccessToast === false) {
				return;
			}

			if (reloadTable) {
				reloadTable();
			}

			toast.dark(
				<ToastContent
					type="success"
					title={toastSuccessTitle}
					subtitle={toastSuccessMessage}
					onClose={() => toast.dismiss()}
				/>,
				{
					position: "top-center",
					autoClose: 3000,
					closeOnClick: false,
					progressClassName: "confirmation-toast-success-progress",
					className: "confirmation-toast",
					bodyClassName: "confirmation-toast-body"
				}
			);
		} catch (error) {
			setIsLoading(false);
			toast.error(
				<ToastContent
					type="error"
					title="Erro"
					subtitleError={error}
					onClose={() => toast.dismiss()}
				/>,
				{ hideProgressBar: true }
			);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={modalTitle}
			cssClass="modal-cancel-confirm"
			kind={kind}
		>
			<span className="modal-info">{modalInfo}</span>
			<div className="button-section">
				<Button
					kind="default"
					styled={changeButtonsPriority ? "primary" : "secondary"}
					onClick={onCloseButtonClick}
					cssClass={changeButtonsPriority ? "confirm-button" : "cancel-button"}
				>
					{closeLabel}
				</Button>
				<Button
					kind="default"
					styled={changeButtonsPriority ? "secondary" : "primary"}
					onClick={() => {
						setIsLoading(true);
						confirm();
					}}
					cssClass={changeButtonsPriority ? "cancel-button" : "confirm-button"}
					isLoading={isLoading}
				>
					{confirmLabel}
				</Button>
			</div>
		</Modal>
	);
}
