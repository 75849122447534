import Label from "components/Label";
import { InputHTMLAttributes } from "react";
import "./styles.scss";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	checked?: boolean;
	label: string;
	error?: string | undefined;
	className?: string;
};

export default function Input({
	checked,
	label,
	placeholder,
	type,
	name,
	value,
	required,
	disabled,
	error,
	onChange,
	className
}: InputProps) {
	return (
		<div className="input-container">
			<Label hasDisabled={disabled} isRequired={!!required}>
				{label}
			</Label>
			<input
				checked={checked}
				type={type}
				name={name}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				className={`input-component ${className || ""}`}
				required={required}
			/>
			{error && <div className="error-message">{error}</div>}
		</div>
	);
}
